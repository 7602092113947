import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 — Not found" />
    <div className={"relative bg-white py-16"}>
      <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"}>
        <h2 className="heading-primary">Page Not Found</h2>

        <p className="mt-8 text-primary">
          Try the <Link className="underline" to="/">home page</Link> instead.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
